import { formatUserLocalStorageKey, UNIQ_LOCAL_STORAGE_KEYS } from 'app/persistedState';
import { SHIPPING_ADDRESS_FIELD } from 'features/OrderManagement/AddOrder/constants';
import {
  ORDER_LINES_COLUMNS,
  ORDER_LINES_COLUMNS_STORAGE_SETTINGS,
  ORDER_LINES_COLUMNS_DEFAULT_ORDER,
  ORDER_LINES_FILTERS,
  ORDER_LINES_QUICK_FILTERS,
} from './orderLines';
import {
  ORDER_LIST_COLUMNS,
  ORDER_LIST_COLUMNS_STORAGE_SETTINGS,
  ORDER_LIST_COLUMNS_DEFAULT_ORDER,
  ORDER_LIST_FILTERS,
  ORDER_LIST_QUICK_FILTERS,
} from './orderList';

export {
  ORDER_LINES_COLUMNS,
  ORDER_LINES_COLUMNS_STORAGE_SETTINGS,
  ORDER_LINES_COLUMNS_DEFAULT_ORDER,
  ORDER_LINES_FILTERS,
  ORDER_LINES_QUICK_FILTERS,
  ORDER_LIST_COLUMNS,
  ORDER_LIST_COLUMNS_STORAGE_SETTINGS,
  ORDER_LIST_COLUMNS_DEFAULT_ORDER,
  ORDER_LIST_FILTERS,
  ORDER_LIST_QUICK_FILTERS,
};

export const ACTIONS = {
  INIT_CONTAINER: 'ORDER_VIEW__INIT_CONTAINER',
  EXIT_CONTAINER: 'ORDER_VIEW__EXIT_CONTAINER',

  SET_IS_FETCHING_FEED: 'ORDER_VIEW__SET_IS_FETCHING_FEED',

  LOAD_ORDER_LINES: 'ORDER_VIEW__LOAD_ORDER_LINES',
  LOAD_ORDER_LINES_SUCCESS: 'ORDER_VIEW__LOAD_ORDER_LINES_SUCCESS',
  LOAD_ORDER_LINES_ERROR: 'ORDER_VIEW__LOAD_ORDER_LINES_ERROR',

  SET_COLUMNS: 'ORDER_VIEW__SET_COLUMNS',
  SET_COLUMNS_ORDER: 'ORDER_VIEW__SET_COLUMNS_ORDER',
  SET_COLUMN_ENABLED: 'ORDER_VIEW__SET_COLUMN_ENABLED',

  SET_DAYS_RANGE: 'ORDER_VIEW__SET_DAYS_RANGE',
  SET_ITEMS_PER_PAGE: 'ORDER_VIEW__SET_ITEMS_PER_PAGE',

  SHOW_OPTIONS_MODAL: 'ORDER_VIEW__SHOW_OPTIONS_MODAL',

  GO_TO_PAGE: 'ORDER_VIEW__GO_TO_PAGE',
  REFRESH_ORDER_FEED: 'ORDER_VIEW__REFRESH_ORDER_FEED',
  SORT_ORDER_FEED: 'ORDER_VIEW__SORT_ORDER_FEED',
  SET_FILTER_OPERAND: 'ORDER_VIEW__SET_FILTER_OPERAND',
  UNSET_FILTER_OPERAND: 'ORDER_VIEW__UNSET_FILTER_OPERAND',
  CLEAR_FILTERS: 'ORDER_VIEW__CLEAR_FILTERS',
  SET_FILTERS: 'ORDER_VIEW__SET_FILTERS',
  ARRANGE_FILTERS: 'ORDER_VIEW__ARRANGE_FILTERS',

  EXPORT_TO_FILE: 'ORDER_VIEW__EXPORT_TO_FILE',
  EXPORT_TO_FILE_SUCCESS: 'ORDER_VIEW__EXPORT_TO_FILE_SUCCESS',
  EXPORT_TO_FILE_ERROR: 'ORDER_VIEW__EXPORT_TO_FILE_ERROR',

  EDIT_ORDER_LINE: 'ORDER_VIEW__EDIT_ORDER_LINE',
  CANCEL_EDIT_ORDER_LINE: 'ORDER_VIEW__CANCEL_EDIT_ORDER_LINE',

  CANCELLING_ORDER_LINE: 'ORDER_VIEW__CANCELLING_ORDER_LINE',

  CANCEL_ORDER_LINE:'ORDER_VIEW__CANCEL_ORDER_LINE',
  CANCEL_ORDER_LINE_SUCCESS:'ORDER_VIEW__CANCEL_ORDER_LINE_SUCCESS',
  CANCEL_ORDER_LINE_ERROR:'ORDER_VIEW__CANCEL_ORDER_LINE_ERROR',

  UPDATE_ORDER_LINE: 'ORDER_VIEW__UPDATE_ORDER_LINE',
  UPDATE_ORDER_LINE_SUCCESS: 'ORDER_VIEW__UPDATE_ORDER_LINE_SUCCESS',
  UPDATE_ORDER_LINE_ERROR: 'ORDER_VIEW__UPDATE_ORDER_LINE_ERROR',

  SWEEP_ORDER_LINE: 'ORDER_VIEW__SWEEP_ORDER_LINE',
  SWEEP_ORDER_LINE_SUCCESS: 'ORDER_VIEW__SWEEP_ORDER_LINE_SUCCESS',
  SWEEP_ORDER_LINE_ERROR: 'ORDER_VIEW__SWEEP_ORDER_LINE_ERROR',

  SEARCH_ORDERS: 'ORDER_VIEW__SEARCH_ORDERS',
  SEARCH_ORDERS_SUCCESS: 'ORDER_VIEW__SEARCH_ORDERS_SUCCESS',
  SEARCH_ORDERS_ERROR: 'ORDER_VIEW__SEARCH_ORDERS_ERROR',
  SHOW_SEARCH_ORDERS_MODAL: 'ORDER_VIEW__SHOW_SEARCH_ORDERS_MODAL',
  RESET_SEARCH_ORDERS: 'ORDER_VIEW__RESET_SEARCH_ORDERS',
  SORT_SEARCH_ORDER_RESULTS: 'ORDER_VIEW__SORT_SEARCH_ORDER_RESULTS',
  SORT_SEARCH_ORDER_RESULTS_SUCCESS: 'ORDER_VIEW__SORT_SEARCH_ORDER_RESULTS_SUCCESS',

  CREATE_ORDER_FROM_ORDER_LINE: 'ORDER_VIEW__CREATE_ORDER_FROM_ORDER_LINE',
  CONFIRM_CREATE_ORDER: 'ORDER_VIEW__CONFIRM_CREATE_ORDER',
  CONFIRM_CREATE_ORDER_SUCCESS: 'ORDER_VIEW__CONFIRM_CREATE_ORDER_SUCCESS',
  CONFIRM_CREATE_ORDER_ERROR: 'ORDER_VIEW__CONFIRM_CREATE_ORDER_ERROR',
  CANCEL_CREATE_ORDER: 'ORDER_VIEW__CANCEL_CREATE_ORDER',
  SHOW_CREATE_ORDER_MODAL: 'ORDER_VIEW__SHOW_CREATE_ORDER_MODAL',
  REDIRECT_TO_ADD_ORDER: 'ORDER_VIEW__REDIRECT_TO_ADD_ORDER',

  SET_ORDER_VIEW_TYPE: 'ORDER_VIEW__SET_ORDER_VIEW_TYPE',
  SET_FILTER_OPTIONS: 'ORDER_VIEW__SET_FILTER_OPTIONS',
  SET_COLUMN_FILTER_OPTIONS: 'ORDER_VIEW__SET_COLUMN_FILTER_OPTIONS',
  SET_QUICK_FILTER_OPTIONS: 'ORDER_VIEW__SET_QUICK_FILTER_OPTIONS',

  LOAD_ORDER_DETAILS: 'ORDER_VIEW__LOAD_ORDER_DETAILS',
  LOAD_ORDER_DETAILS_SUCCESS: 'ORDER_VIEW__LOAD_ORDER_DETAILS_SUCCESS',
  LOAD_ORDER_DETAILS_ERROR: 'ORDER_VIEW__LOAD_ORDER_DETAILS_ERROR',
  SHOW_ORDER_DETAILS_MODAL: 'ORDER_VIEW__SHOW_ORDER_DETAILS_MODAL',
  RESET_ORDER_DETAILS: 'ORDER_VIEW__RESET_ORDER_DETAILS',
  ADD_ORDER_NOTE: 'ORDER_VIEW__ADD_ORDER_NOTE',
  ADD_ORDER_NOTE_SUCCESS: 'ORDER_VIEW__ADD_ORDER_NOTE_SUCCESS',
  ADD_ORDER_NOTE_ERROR: 'ORDER_VIEW__ADD_ORDER_NOTE_ERROR',

  FETCH_ORDER_LINE_NOTES: 'ORDER_VIEW__FETCH_ORDER_LINE_NOTES',
  FETCH_ORDER_LINE_NOTES_SUCCESS: 'ORDER_VIEW__FETCH_ORDER_LINE_NOTES_SUCCESS',
  FETCH_ORDER_LINE_NOTES_ERROR: 'ORDER_VIEW__FETCH_ORDER_LINE_NOTES_ERROR',

  ADD_ORDER_LINE_NOTE: 'ORDER_VIEW__ADD_ORDER_LINE_NOTE',
  CANCEL_ADD_ORDER_LINE_NOTE: 'ORDER_VIEW__CANCEL_ADD_ORDER_LINE_NOTE',
  SET_NEW_NOTE: 'ORDER_VIEW__SET_NEW_NOTE',
  SUBMIT_ORDER_LINE_NOTE: 'ORDER_VIEW__SUBMIT_ORDER_LINE_NOTE',
  SUBMIT_ORDER_LINE_NOTE_SUCCESS: 'ORDER_VIEW__SUBMIT_ORDER_LINE_NOTE_SUCCESS',
  SUBMIT_ORDER_LINE_NOTE_ERROR: 'ORDER_VIEW__SUBMIT_ORDER_LINE_NOTE_ERROR',
};

export const SEARCH_ORDERS_COLUMNS_DEFAULT_ORDER = [
  'order_id',
  'sales_order_number',
  'purchase_order_number',
  'sku',
  'upc',
  'qty_ordered',
  'price',
  'brand',
  'purchase_date',
  'sales_channel',
  'address',
];

export const LINE_TYPES = [
  {
    id: 'P',
    value:'P',
    label: 'P - Warehouse',
  },
  {
    id: 'D',
    value: 'D',
    label: 'D - Dropship',
  },
  {
    id: 'S',
    value: 'S',
    label: 'S - Trans-ship',
  },
  {
    id: 'X',
    value: 'X',
    label: 'X - Attention needed',
  },
];

export const ORDER_VIEW_LOCAL_STORAGE_KEY = username => formatUserLocalStorageKey(
  username, UNIQ_LOCAL_STORAGE_KEYS.ORDER_VIEW,
);

export const SHIPPING_INFO_ATTRIBUTES = Object.values(SHIPPING_ADDRESS_FIELD);

export const ORDER_VIEW_ERRORS = {
  NO_RESULTS_FOR_SO: salesOrderNumber => `Something went wrong! No search results found for SO# ${salesOrderNumber}`,
};

export const ORDER_VIEW_TYPE = {
  ORDER_LINE: 'order_line',
  ORDER_LIST: 'order_list',
};

export const ORDER_VIEW_TYPE_OPTIONS = {
  [ORDER_VIEW_TYPE.ORDER_LINE]: {
    key: ORDER_VIEW_TYPE.ORDER_LINE,
    title: 'Order Line',
  },
  [ORDER_VIEW_TYPE.ORDER_LIST]: {
    key: ORDER_VIEW_TYPE.ORDER_LIST,
    title: 'Order List',
  },
};

export const ORDER_VIEW_FILTER_OPTIONS = {
  [ORDER_VIEW_TYPE.ORDER_LINE]: ORDER_LINES_FILTERS,
  [ORDER_VIEW_TYPE.ORDER_LIST]: ORDER_LIST_FILTERS,
};

export const ORDER_VIEW_COLUMN_FILTER_OPTIONS = {
  [ORDER_VIEW_TYPE.ORDER_LINE]: ORDER_LINES_FILTERS,
  [ORDER_VIEW_TYPE.ORDER_LIST]: ORDER_LIST_FILTERS,
};

export const ORDER_VIEW_QUICK_FILTER_OPTIONS = {
  [ORDER_VIEW_TYPE.ORDER_LINE]: ORDER_LINES_QUICK_FILTERS,
  [ORDER_VIEW_TYPE.ORDER_LIST]: ORDER_LIST_QUICK_FILTERS,
};