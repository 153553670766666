import {
  all, takeEvery, put, call, select,
} from 'redux-saga/effects';
import _isEmpty from 'lodash/isEmpty';
import { toast } from 'react-toastify';
import {
  fetchEditProductsTemplate,
  setImportProductsFile,
  setValidateProductsFile,
  fetchDeleteProductsTemplate,
  fetchProductsUploadLogs,
  getDownloadUploadedFile,
  postCancelUploadRequest,
  getDownloadErrorFile,
  fetchAddProductsTemplate,
} from 'services/productManagement/actions';
import { fileSaverSaga, errorToasterSaga } from 'services/sagaWorkers';
import { ACTIONS, ERROR_FILE_NAME } from './constants';
import {
  downloadEditTemplateSuccess,
  downloadEditTemplateError,
  validateUploadFileSuccess,
  validateUploadFileError,
  setUploadFileIsValid,
  setUploadFileValidationErrors,
  uploadFileSuccess,
  uploadFileError,
  downloadDeleteTemplateSuccess,
  downloadDeleteTemplateError,
  loadUploadLogs,
  loadUploadLogsSuccess,
  loadUploadLogsError,
  setUploadLogs,
  setUploadType,
  downloadOriginalFileSuccess,
  downloadOriginalFileError,
  cancelUploadRequestSuccess,
  cancelUploadRequestError,
  downloadErrorFileSuccess,
  downloadErrorFileError,
  downloadAddTemplateSuccess,
  downloadAddTemplateError,
} from './actions';
import { selectOriginalFileName, selectUploadType } from './selectors';

export const EDIT_TEMPLATE_FILENAME = 'products_edit_template.xlsx';
export const ADD_TEMPLATE_FILENAME = 'products_add_template.xlsx';
export const DELETE_TEMPLATE_FILENAME = 'products_delete_template.xlsx';

function* initContainerWatcher() {
  yield put(loadUploadLogs());
}

function* downloadEditTemplateWatcher() {
  yield put(fetchEditProductsTemplate(downloadEditTemplateSuccess, downloadEditTemplateError));
}

function* downloadEditTemplateSuccessWatcher({ payload }) {
  yield call(fileSaverSaga, {
    fileName: EDIT_TEMPLATE_FILENAME,
    fileData: payload,
    blobType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    errorActionCallback: downloadEditTemplateError,
  });
}

function* downloadAddTemplateWatcher() {
  yield put(fetchAddProductsTemplate(downloadAddTemplateSuccess, downloadAddTemplateError));
}

function* downloadAddTemplateSuccessWatcher({ payload }) {
  yield call(fileSaverSaga, {
    fileName: ADD_TEMPLATE_FILENAME,
    fileData: payload,
    blobType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    errorActionCallback: downloadAddTemplateError,
  });
}

function* downloadDeleteTemplateWatcher() {
  yield put(
    fetchDeleteProductsTemplate(downloadDeleteTemplateSuccess, downloadDeleteTemplateError),
  );
}

function* downloadDeleteTemplateSuccessWatcher({ payload }) {
  yield call(fileSaverSaga, {
    fileName: DELETE_TEMPLATE_FILENAME,
    fileData: payload,
    blobType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    errorActionCallback: downloadEditTemplateError,
  });
}

function* validateUploadFileWatcher({ file }) {
  const uploadType = yield select(selectUploadType);
  yield put(setUploadFileValidationErrors(null));
  yield put(setUploadFileIsValid(false));
  yield put(
    setValidateProductsFile(
      file,
      uploadType,
      validateUploadFileSuccess,
      validateUploadFileError,
    ),
  );
}

function* validateUploadFileSuccessWatcher({ payload }) {
  const { success, validation_errors: validationErrors } = payload;

  if (success || _isEmpty(validationErrors)) {
    yield put(setUploadFileIsValid(true));
    return;
  }

  yield put(setUploadFileIsValid(false));
  yield put(setUploadFileValidationErrors(validationErrors));
}

function* uploadFileWatcher({ file }) {
  const uploadType = yield select(selectUploadType);
  yield put(setImportProductsFile(file, uploadType, uploadFileSuccess, uploadFileError));
}

function* uploadFileSuccessWatcher() {
  yield put(setUploadFileIsValid(false));
  yield put(setUploadType(null));
  yield call([toast, toast.success], 'File successfully imported.');
  yield put(loadUploadLogs());
}

function* loadUploadLogsWatcher() {
  yield put(fetchProductsUploadLogs(loadUploadLogsSuccess, loadUploadLogsError));
}

function* loadUploadLogsSuccessWatcher({ payload }) {
  yield put(setUploadLogs(payload.logs));
}

function* downloadOriginalFileWatcher({ fileKey }) {
  yield put(
    getDownloadUploadedFile(fileKey, downloadOriginalFileSuccess, downloadOriginalFileError),
  );
}

function* downloadOriginalFileSuccessWatcher({ payload }) {
  const originalFileName = yield select(selectOriginalFileName);

  yield call(fileSaverSaga, {
    fileName: originalFileName,
    fileData: payload,
    blobType: 'text/csv;charset=utf-8;',
    errorActionCallback: downloadOriginalFileError,
  });
}

function* cancelUploadRequestWatcher({ fileKey }) {
  yield put(
    postCancelUploadRequest(fileKey, cancelUploadRequestSuccess, cancelUploadRequestError),
  );
}

function* cancelUploadRequestSuccessWatcher({ payload }) {
  const { success } = payload;
  if (success) {
    yield put(loadUploadLogs());
  }
}

function* downloadErrorFileWatcher({ fileKey }) {
  yield put(
    getDownloadErrorFile(fileKey, downloadErrorFileSuccess, downloadErrorFileError),
  );
}

function* downloadErrorFileSuccessWatcher({ payload }) {
  const originalFileName = yield select(selectOriginalFileName);

  yield call(fileSaverSaga, {
    fileName: ERROR_FILE_NAME(originalFileName),
    fileData: payload,
    blobType: 'text/plain;charset=utf-8;',
    errorActionCallback: downloadErrorFileError,
  });
}

export default function* productCreatorSagas() {
  yield all([
    takeEvery(ACTIONS.INIT_CONTAINER, initContainerWatcher),

    takeEvery(ACTIONS.DOWNLOAD_EDIT_TEMPLATE, downloadEditTemplateWatcher),
    takeEvery(ACTIONS.DOWNLOAD_EDIT_TEMPLATE_SUCCESS, downloadEditTemplateSuccessWatcher),
    takeEvery(ACTIONS.DOWNLOAD_EDIT_TEMPLATE_ERROR, errorToasterSaga),

    takeEvery(ACTIONS.DOWNLOAD_ADD_TEMPLATE, downloadAddTemplateWatcher),
    takeEvery(ACTIONS.DOWNLOAD_ADD_TEMPLATE_SUCCESS, downloadAddTemplateSuccessWatcher),
    takeEvery(ACTIONS.DOWNLOAD_ADD_TEMPLATE_ERROR, errorToasterSaga),

    takeEvery(ACTIONS.DOWNLOAD_DELETE_TEMPLATE, downloadDeleteTemplateWatcher),
    takeEvery(ACTIONS.DOWNLOAD_DELETE_TEMPLATE_SUCCESS, downloadDeleteTemplateSuccessWatcher),
    takeEvery(ACTIONS.DOWNLOAD_DELETE_TEMPLATE_ERROR, errorToasterSaga),

    takeEvery(ACTIONS.VALIDATE_UPLOAD_FILE, validateUploadFileWatcher),
    takeEvery(ACTIONS.VALIDATE_UPLOAD_FILE_SUCCESS, validateUploadFileSuccessWatcher),
    takeEvery(ACTIONS.VALIDATE_UPLOAD_FILE_ERROR, errorToasterSaga),

    takeEvery(ACTIONS.UPLOAD_FILE, uploadFileWatcher),
    takeEvery(ACTIONS.UPLOAD_FILE_SUCCESS, uploadFileSuccessWatcher),
    takeEvery(ACTIONS.UPLOAD_FILE_ERROR, errorToasterSaga),

    takeEvery(ACTIONS.LOAD_UPLOAD_LOGS, loadUploadLogsWatcher),
    takeEvery(ACTIONS.LOAD_UPLOAD_LOGS_SUCCESS, loadUploadLogsSuccessWatcher),
    takeEvery(ACTIONS.LOAD_UPLOAD_LOGS_ERROR, errorToasterSaga),

    takeEvery(ACTIONS.DOWNLOAD_ORIGINAL_FILE, downloadOriginalFileWatcher),
    takeEvery(ACTIONS.DOWNLOAD_ORIGINAL_FILE_SUCCESS, downloadOriginalFileSuccessWatcher),
    takeEvery(ACTIONS.DOWNLOAD_ORIGINAL_FILE_ERROR, errorToasterSaga),

    takeEvery(ACTIONS.CANCEL_UPLOAD_REQUEST, cancelUploadRequestWatcher),
    takeEvery(ACTIONS.CANCEL_UPLOAD_REQUEST_SUCCESS, cancelUploadRequestSuccessWatcher),
    takeEvery(ACTIONS.CANCEL_UPLOAD_REQUEST_ERROR, errorToasterSaga),

    takeEvery(ACTIONS.DOWNLOAD_ERROR_FILE, downloadErrorFileWatcher),
    takeEvery(ACTIONS.DOWNLOAD_ERROR_FILE_SUCCESS, downloadErrorFileSuccessWatcher),
    takeEvery(ACTIONS.DOWNLOAD_ERROR_FILE_ERROR, errorToasterSaga),
  ]);
}
