import { createReducer } from 'redux-create-reducer';
import { ACTIONS } from '../constants';

export const INITIAL_STATE = {
  selectedOrderLine: null,
  showEditOrderLineModal: false,
  isUpdatingOrderLine: false,
  isSweepingOrderLine: false,
  showCancelOrderLineModal:false,
  setIsCancellingOrderLine:false
};

const setEditOrderLine = (state, { selectedOrderLine }) => ({
  ...state,
  showEditOrderLineModal: true,
  selectedOrderLine,
});

const setCancelOrderLine = (state, { selectedOrderLine }) => ({
  ...state,
  showCancelOrderLineModal: true,
  selectedOrderLine,
});


const setCancelEditOrderLine = () => ({
  ...INITIAL_STATE,
});

const setIsUpdatingOrderLine = (state, isUpdatingOrderLine) => ({
  ...state,
  isUpdatingOrderLine,
});

const setIsCancellingOrderLine = (state, isCancellingOrderLine) => ({
  ...state,
  isCancellingOrderLine,
});

const setIsSweepingOrderLine = (state, isSweepingOrderLine) => ({
  ...state,
  isSweepingOrderLine,
});

export const handlers = {
  [ACTIONS.EDIT_ORDER_LINE]: setEditOrderLine,
  [ACTIONS.CANCEL_EDIT_ORDER_LINE]: setCancelEditOrderLine,

  [ACTIONS.CANCELLING_ORDER_LINE]: setCancelOrderLine,

  [ACTIONS.CANCEL_ORDER_LINE]: state => setIsCancellingOrderLine(state, false),

  [ACTIONS.CANCEL_ORDER_LINE_SUCCESS]: state => setIsCancellingOrderLine(state, false),
  [ACTIONS.CANCEL_ORDER_LINE_ERROR]: state => setIsCancellingOrderLine(state, false),


  [ACTIONS.UPDATE_ORDER_LINE]: state => setIsUpdatingOrderLine(state, true),
  [ACTIONS.UPDATE_ORDER_LINE_SUCCESS]: state => setIsUpdatingOrderLine(state, false),
  [ACTIONS.UPDATE_ORDER_LINE_ERROR]: state => setIsUpdatingOrderLine(state, false),

  [ACTIONS.SWEEP_ORDER_LINE]: state => setIsSweepingOrderLine(state, true),
  [ACTIONS.SWEEP_ORDER_LINE_SUCCESS]: state => setIsSweepingOrderLine(state, false),
  [ACTIONS.SWEEP_ORDER_LINE_ERROR]: state => setIsSweepingOrderLine(state, false),
};

export default createReducer(INITIAL_STATE, handlers);