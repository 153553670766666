import {
  all, takeEvery, put, call,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { setUpdateFlag, fetchSweepOrderLine,setCancelOrderLines } from 'services/orders/actions';
import { ACTIONS } from '../constants';
import {
  updateOrderLineSuccess,
  updateOrderLineError,
  cancelEditOrderLine,
  sweepOrderLineSuccess,
  sweepOrderLineError,
  cancelOrderLineSuccess,
  cancelOrderLineError,
  
} from '../actions';
import { loadOrderLinesWatcher } from './loadOrderLinesSagas';

function* updateOrderLineWatcher({ guidOrderLine, params }) {
  yield put(setUpdateFlag(
    guidOrderLine,
    params,
    updateOrderLineSuccess,
    updateOrderLineError,
  ));
}

function* updateOrderLineSuccessWatcher() {
  yield call([toast, toast.success], 'Order Line Updated!');
  yield call(loadOrderLinesWatcher);
  yield put(cancelEditOrderLine());
}

function* updateOrderLineErrorWatcher({ errorMessage }) {
  yield call([toast, toast.error], errorMessage);
}

function* cancelOrderLineWatcher({ guidOrderLine, params }) {
  yield put(setCancelOrderLines(
    guidOrderLine,
    params,
    cancelOrderLineSuccess,
    cancelOrderLineError,
  ));
}

function* cancelOrderLineSuccessWatcher() {
  yield call([toast, toast.success], 'Order Line Updated!');
  yield call(loadOrderLinesWatcher);
  yield put(cancelEditOrderLine());
}

function* cancelOrderLineErrorWatcher({ errorMessage }) {
  yield call([toast, toast.error], errorMessage);
}


function* sweepOrderLineWatcher({ guidOrderLine, salesOrderNumber }) {
  yield put(fetchSweepOrderLine(
    guidOrderLine,
    salesOrderNumber,
    sweepOrderLineSuccess,
    sweepOrderLineError,
  ));
}

function* sweepOrderLineSuccessWatcher() {
  yield call([toast, toast.success], 'Order Line Sweep!');
  yield call(loadOrderLinesWatcher);
  yield put(cancelEditOrderLine());
}

function* sweepOrderLineErrorWatcher({ errorMessage }) {
  yield call([toast, toast.error], errorMessage);
}

export default function* orderLineManagementSagas() {
  yield all([
    takeEvery(ACTIONS.UPDATE_ORDER_LINE, updateOrderLineWatcher),
    takeEvery(ACTIONS.UPDATE_ORDER_LINE_SUCCESS, updateOrderLineSuccessWatcher),
    takeEvery(ACTIONS.UPDATE_ORDER_LINE_ERROR, updateOrderLineErrorWatcher),

    takeEvery(ACTIONS.SWEEP_ORDER_LINE, sweepOrderLineWatcher),
    takeEvery(ACTIONS.SWEEP_ORDER_LINE_SUCCESS, sweepOrderLineSuccessWatcher),
    takeEvery(ACTIONS.SWEEP_ORDER_LINE_ERROR, sweepOrderLineErrorWatcher),

    takeEvery(ACTIONS.CANCEL_ORDER_LINE, cancelOrderLineWatcher),
    takeEvery(ACTIONS.CANCEL_ORDER_LINE_SUCCESS, cancelOrderLineSuccessWatcher),
    takeEvery(ACTIONS.CANCEL_ORDER_LINE_ERROR, cancelOrderLineErrorWatcher),
  ]);
}