export const ACTIONS = {
  SCAN_SALES_ORDER: 'SHIPPING__SCAN_SALES_ORDER',
  SCAN_SALES_ORDER_SUCCESS: 'SHIPPING__SCAN_SALES_ORDER_SUCCESS',
  SCAN_SALES_ORDER_ERROR: 'SHIPPING__SCAN_SALES_ORDER_ERROR',
  SET_SCANNED_ITEMS: 'SHIPPING__SET_SCANNED_ITEMS',
  SET_SALES_ORDER_NUMBER: 'SHIPPING__SET_SALES_ORDER_NUMBER',
  CONFIRM_SHIPMENT: 'SHIPPING__CONFIRM_SHIPMENT',
  CONFIRM_SHIPMENT_SUCCESS: 'SHIPPING__CONFIRM_SHIPMENT_SUCCESS',
  CONFIRM_SHIPMENT_ERROR: 'SHIPPING__CONFIRM_SHIPMENT_ERROR',
  TOGGLE_SHIPMENTS:'SHIPPING__TOGGLE_SHIPMENTS'
};