import { createReducer } from 'redux-create-reducer';
import { ACTIONS } from '../constants';

const INITIAL_STATE = {
  order_id: '',
  purchase_date: '',
  order_status: 0,
  order_total: '',
  total_qty: 0,
  total_qty_shipped: '',
  referring_order: '',
  order_notes: [],
  isConfirmingOrder: false,
  shippingItem: {},
  showConfirmPaymentModal: false,
  showCancelOrderModal: false,
  paymentConfirmed: false,
  orderConfirmed: false,
};

const updateOrderId = (state, { orderId }) => ({
  ...state,
  order_id: orderId,
});

const updatePurchaseDate = (state, { purchaseDate }) => ({
  ...state,
  purchase_date: purchaseDate,
});

const updateReferringOrder = (state, { referringOrder }) => ({
  ...state,
  referring_order: referringOrder,
});

const updateOrderNotes = (state, { orderNotes }) => ({
  ...state,
  order_notes: [orderNotes]
});

const setIsConfirmingOrder = (state, isConfirmingOrder) => ({
  ...state,
  isConfirmingOrder,
});

const updateShippingItem = (state, { shippingItem }) => ({
  ...state,
  shippingItem: {
    sku: 'Shipping',
    qty_ordered: '1',
    price: '0.00',
    qty_shipped: '0',
    referring_replacement_id: '',
    referring_return_id: '',
    ...shippingItem,
  },
});

const deleteShippingItem = state => ({
  ...state,
  shippingItem: INITIAL_STATE.shippingItem,
});

const updateShowConfirmPaymentModal = (state, { showConfirmPaymentModal }) => ({
  ...state,
  showConfirmPaymentModal,
});

const updateShowCancelOrderModal = (state, { showCancelOrderModal }) => ({
  ...state,
  showCancelOrderModal,
});

const setPaymentConfirmed = (state, { payload }) => ({
  ...state,
  paymentConfirmed: payload,
});

const setOrderConfirmed = state => ({
  ...state,
  orderConfirmed: true,
  isConfirmingOrder: false,
});

const handlers = {
  [ACTIONS.SET_ORDER_ID]: updateOrderId,
  [ACTIONS.SET_PURCHASE_DATE]: updatePurchaseDate,
  [ACTIONS.SET_REFERRING_ORDER]: updateReferringOrder,
  [ACTIONS.SET_ORDER_NOTES]: updateOrderNotes,
  [ACTIONS.CONFIRM_ORDER]: state => setIsConfirmingOrder(state, true),
  [ACTIONS.CONFIRM_ORDER_SUCCESS]: setOrderConfirmed,
  [ACTIONS.CONFIRM_ORDER_ERROR]: state => setIsConfirmingOrder(state, false),
  [ACTIONS.SET_SHIPPING_ITEM]: updateShippingItem,
  [ACTIONS.REMOVE_SHIPPING_ITEM]: deleteShippingItem,
  [ACTIONS.SET_SHOW_CONFIRM_PAYMENT_MODAL]: updateShowConfirmPaymentModal,
  [ACTIONS.SET_SHOW_CANCEL_ORDER_MODAL]: updateShowCancelOrderModal,
  [ACTIONS.CONFIRM_PAYMENT_SUCCESS]: setPaymentConfirmed,
};

export default createReducer(INITIAL_STATE, handlers);