import { createReducer } from 'redux-create-reducer';
import { addFilter, removeFilter } from 'features/helpers';
import { ACTIONS } from '../constants';

export const INITIAL_STATE = {
  data: null,
  showOptionsModal: false,
  showOrderDetailsModal: false,
  lastPage: 1,
  isFetchingFeed: false,
  isRefreshing: false,
  isExporting: false,
  filters: [],
};

const updateIsFetchingFeed = (state, { isFetchingFeed }) => ({
  ...state,
  isFetchingFeed,
});

const setLoadOrderLinesSuccess = (state, { payload }) => ({
  ...state,
  data: payload,
  isFetchingFeed: false,
  isRefreshing: false,
});

const setLoadOrderLinesError = state => ({
  ...state,
  isFetchingFeed: false,
  isRefreshing: false,
});

const setShowOptionsModal = (state, { showOptionsModal }) => ({
  ...state,
  showOptionsModal,
});

const updateLastPage = (state, { lastPage }) => {
  return {
  ...state,
  lastPage,
}};

const setIsRefreshing = (state, isRefreshing) => ({
  ...state,
  isRefreshing,
});

const resetLastPage = state => ({
  ...state,
  lastPage: INITIAL_STATE.lastPage,
});

const setIsExporting = (state, isExporting) => ({
  ...state,
  isExporting,
});

const updateFilters = (state, filters) => ({
  ...state,
  filters,
});

const addFilterOperand = (state, { operand }) => {
  const updatedFilters = addFilter(operand, state.filters);
  return {
    ...updateFilters(state, updatedFilters),
    lastPage: INITIAL_STATE.lastPage,
  };
};

const removeFilterOperand = (state, { groupIndex, filterIndex }) => {
  const updatedFilters = removeFilter(groupIndex, filterIndex, state.filters);
  return {
    ...updateFilters(state, updatedFilters),
    lastPage: INITIAL_STATE.lastPage,
  };
};

const setFiltersList = (state, { filters }) => updateFilters(state, filters);

const resetState = () => ({ ...INITIAL_STATE });

export const handlers = {
  [ACTIONS.SET_IS_FETCHING_FEED]: updateIsFetchingFeed,

  [ACTIONS.LOAD_ORDER_LINES_SUCCESS]: setLoadOrderLinesSuccess,
  [ACTIONS.LOAD_ORDER_LINES_ERROR]: setLoadOrderLinesError,
  [ACTIONS.SHOW_OPTIONS_MODAL]: setShowOptionsModal,
  [ACTIONS.GO_TO_PAGE]: updateLastPage,
  [ACTIONS.REFRESH_ORDER_FEED]: state => setIsRefreshing(state, true),

  [ACTIONS.SET_DAYS_RANGE]: resetLastPage,
  [ACTIONS.SET_ITEMS_PER_PAGE]: resetLastPage,
  [ACTIONS.SET_FILTER_OPERAND]: addFilterOperand,
  [ACTIONS.UNSET_FILTER_OPERAND]: removeFilterOperand,
  [ACTIONS.CLEAR_FILTERS]: state => updateFilters(state, INITIAL_STATE.filters),
  [ACTIONS.SET_FILTERS]: setFiltersList,

  [ACTIONS.EXPORT_TO_FILE]: state => setIsExporting(state, true),
  [ACTIONS.EXPORT_TO_FILE_SUCCESS]: state => setIsExporting(state, false),
  [ACTIONS.EXPORT_TO_FILE_ERROR]: state => setIsExporting(state, false),

  [ACTIONS.EXIT_CONTAINER]: resetState,
};

export default createReducer(INITIAL_STATE, handlers);