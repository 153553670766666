import styled from 'styled-components';
import { genericBorder } from 'assets/styles/utils';
import {
  PORCELAIN,
  OLD_LACE,
  ATHENS_GRAY,
  FETA,
  WISP_PINK,
  FETA_DARK_5,
  SELAGO_DARK_5,
  OLD_LACE_DARK_5,
  WISP_PINK_DARK_5,
} from 'constants/colors';
import { ROBOTO, ROBOTO_MEDIUM } from 'assets/styles/fonts';
import { FieldWrap } from 'components/Field/styles';
import { CircleButtonWrap } from 'components/CircleButton/styles';
import Skeleton from 'components/Skeleton';

const columnAlign = {
  left: 'left',
  right: 'right',
  center: 'center',
};
const columnInnerJustifyContent = {
  [columnAlign.left]: 'flex-start',
  [columnAlign.right]: 'flex-end',
  [columnAlign.center]: 'center',
};
const columnVerticalAlign = {
  top: 'top',
  bottom: 'bottom',
  center: 'center',
};
const columnJustifyContent = {
  [columnVerticalAlign.top]: 'flex-start',
  [columnVerticalAlign.bottom]: 'flex-end',
  [columnVerticalAlign.center]: 'center',
};

export const Grid = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: ${genericBorder()};
  max-width: ${({ maxWidth }) => (maxWidth || '100%')};
  min-width: ${({ minWidth }) => (minWidth || '0')};
  max-height: ${({ maxHeight }) => (maxHeight || '100%')};
  min-height: ${({ minHeight }) => (minHeight || '0')};
  box-sizing: border-box;
  ${({ overflow }) => overflow && `
    overflow: ${overflow};
  `}
`;

export const gridColors = {
  default: 'default',
  danger: 'danger',
  danger_dark: 'danger_dark',
  success: 'success',
  success_dark: 'success_dark',
  info: 'info',
  info_dark: 'info_dark',
  warning: 'warning',
  warning_dark: 'warning_dark',
};

export const highlightColors = {
  [gridColors.default]: ATHENS_GRAY,
  [gridColors.danger]: WISP_PINK,
  [gridColors.danger_dark]: WISP_PINK_DARK_5,
  [gridColors.success]: FETA,
  [gridColors.success_dark]: FETA_DARK_5,
  [gridColors.info]: OLD_LACE_DARK_5,
  [gridColors.info_dark]: SELAGO_DARK_5,
  [gridColors.warning]: OLD_LACE,
  [gridColors.warning_dark]: OLD_LACE_DARK_5,
};
export const GridRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  align-items: stretch;
  width: 100%;
  font-family: ${ROBOTO};
  box-sizing: border-box;
  min-height: ${({ minHeight }) => (minHeight || '1rem')};
  height: ${({ height }) => (height || 'auto')};
  ${({ borderBottom = true }) => borderBottom && `
    border-bottom: ${genericBorder()};
  `}
  ${({ borderTop }) => borderTop && `
    border-top: ${genericBorder()};
  `}
  &:last-child {
    border-bottom: none;
  }
  transition: background-color 0.3s ease;
  ${({ highlight, highlightColor }) => highlight && `
    background-color: ${highlightColors[highlightColor] || highlightColors.default};
  `}

  ${({ scrollbarPad }) => scrollbarPad && `
    overflow-y: scroll;
    &::-webkit-scrollbar {
      visibility: hidden;
    }
  `}
`;

export const GridColumnWrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: ${({ verticalAlign }) => columnJustifyContent[verticalAlign] || columnJustifyContent.center};
  border-right: ${genericBorder()}; 
  width: ${({ width }) => (width || '100%')};
  max-width: ${({ maxWidth }) => (maxWidth || '100%')};
  min-width: ${({ minWidth }) => (minWidth || '50px')};
  height: ${({ height }) => (height || '100%')};
  box-sizing: border-box;
  padding: 0.8rem;
  ${({ mobile }) => mobile && `
    padding: 0.2rem;
  `}
  > div {
    font-size: 0.9rem;
    display: flex;
    text-align: ${({ align }) => columnAlign[align] || columnAlign.left};
    justify-content: ${({ align }) => columnInnerJustifyContent[align] || columnInnerJustifyContent.left};
    > span {
      display: block;
      font-size: inherit;
      overflow: hidden;
      word-break: break-all;
      ${({ truncate }) => truncate && `
        text-overflow: ellipsis;
        white-space: nowrap;
        word-break: unset;
      `}
    }
    > button {
      margin: 0;
    }
    ${FieldWrap} {
      margin: 0;
    }
  }
  &:last-child {
    border-right: none;
  }

  ${Skeleton} {
    height: 0.6rem;
  }

  ${CircleButtonWrap} {
    position: absolute;
    right: 0.8rem;
    margin: auto;
  }

  ${({ isLoading, columnHead }) => isLoading && !columnHead && `
    min-height: 3rem;
    > div {
      display: none;
    }
  `}
  ${({ highlight, columnHead }) => highlight && `
    &::before {
      content: '';
      display: block;
      position: absolute;
      height: 100%;
      top: 0;
      bottom: 0;
      left: 0px;
      right: 0;
      background: ${columnHead ? 'rgba(70,70,70, 0.1)' : 'rgba(150,150,150, 0.1)'};
      z-index: 0;
      transition: background-color 0.3s ease;
    }
    > * {
      z-index: 1;
    }
  `}
  &::after {
    content: '';
    display: block;
    position: absolute;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0px;
    right: 0;
    background: transparent;
    z-index: -1;
    transition: background-color 0.3s ease;
  }
`;

export const GridHead = styled(GridRow)`
  border: none;
  background-color: ${PORCELAIN};
  ${GridColumnWrap} {
    font-family: ${ROBOTO_MEDIUM};
    border-right: none;
    background-color: ${PORCELAIN};
    border-bottom: ${genericBorder()};
  }
  ${({ sticky }) => sticky && `
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1;
  `}
`;

export const GridFoot = styled(GridHead)`
  border-top: ${genericBorder()};
  background-color: transparent;
  ${GridColumnWrap} {
    background-color: transparent;
    border-bottom: none;
  } 
`;

export const GridBody = styled.div`
  overflow: ${({ overflow }) => overflow || 'auto'};
  max-height: ${({ maxHeight }) => maxHeight || '100%'};
  flex: ${({ flex }) => flex || '1'};
`;