import { createAction } from 'redux-create-action';
import { ACTIONS } from './constants';

export const initContainer = createAction(ACTIONS.INIT_CONTAINER);
export const exitContainer = createAction(ACTIONS.EXIT_CONTAINER);

export const setIsFetchingFeed = createAction(ACTIONS.SET_IS_FETCHING_FEED, 'isFetchingFeed');

export const loadOrderLines = createAction(ACTIONS.LOAD_ORDER_LINES);
export const loadOrderLinesSuccess = createAction(ACTIONS.LOAD_ORDER_LINES_SUCCESS, 'payload');
export const loadOrderLinesError = createAction(ACTIONS.LOAD_ORDER_LINES_ERROR, 'errorMessage', 'error');

export const setColumns = createAction(ACTIONS.SET_COLUMNS, 'columns');
export const setColumnsOrder = createAction(ACTIONS.SET_COLUMNS_ORDER, 'columnsOrder');
export const setColumnEnabled = createAction(ACTIONS.SET_COLUMN_ENABLED, 'columnId', 'enabled');

export const setDaysRange = createAction(ACTIONS.SET_DAYS_RANGE, 'days');
export const setItemsPerPage = createAction(ACTIONS.SET_ITEMS_PER_PAGE, 'itemsPerPage');

export const showOptionsModal = createAction(ACTIONS.SHOW_OPTIONS_MODAL, 'showOptionsModal');
export const refreshOrderFeed = createAction(ACTIONS.REFRESH_ORDER_FEED);
export const sortOrderFeed = createAction(ACTIONS.SORT_ORDER_FEED, 'columnId', 'direction');
export const setFilterOperand = createAction(ACTIONS.SET_FILTER_OPERAND, 'operand');
export const unsetFilterOperand = createAction(ACTIONS.UNSET_FILTER_OPERAND, 'groupIndex', 'filterIndex');
export const clearFilters = createAction(ACTIONS.CLEAR_FILTERS);
export const setFilters = createAction(ACTIONS.SET_FILTERS, 'filters');
export const arrangeFilters = createAction(ACTIONS.ARRANGE_FILTERS, 'destination', 'source');

export const goToPage = createAction(ACTIONS.GO_TO_PAGE, 'lastPage');

export const exportToFile = createAction(ACTIONS.EXPORT_TO_FILE);
export const exportToFileSuccess = createAction(ACTIONS.EXPORT_TO_FILE_SUCCESS, 'payload');
export const exportToFileError = createAction(ACTIONS.EXPORT_TO_FILE_ERROR, 'errorMessage', 'error');

export const editOrderLine = createAction(ACTIONS.EDIT_ORDER_LINE, 'selectedOrderLine');
export const cancelEditOrderLine = createAction(ACTIONS.CANCEL_EDIT_ORDER_LINE);

export const cancellingOrderLine = createAction(ACTIONS.CANCELLING_ORDER_LINE, 'selectedOrderLine');


export const cancelOrderLine = createAction(ACTIONS.CANCEL_ORDER_LINE,'guidOrderLine', 'params')
export const cancelOrderLineSuccess = createAction(ACTIONS.CANCEL_ORDER_LINE_SUCCESS,'payload')
export const cancelOrderLineError = createAction(ACTIONS.CANCEL_ORDER_LINE_ERROR,'errorMessage','error')

export const updateOrderLine = createAction(ACTIONS.UPDATE_ORDER_LINE, 'guidOrderLine', 'params');
export const updateOrderLineSuccess = createAction(ACTIONS.UPDATE_ORDER_LINE_SUCCESS, 'payload');
export const updateOrderLineError = createAction(ACTIONS.UPDATE_ORDER_LINE_ERROR, 'errorMessage', 'error');

export const sweepOrderLine = createAction(ACTIONS.SWEEP_ORDER_LINE, 'guidOrderLine', 'salesOrderNumber');
export const sweepOrderLineSuccess = createAction(ACTIONS.SWEEP_ORDER_LINE_SUCCESS, 'payload');
export const sweepOrderLineError = createAction(ACTIONS.SWEEP_ORDER_LINE_ERROR, 'errorMessage', 'error');

export const searchOrders = createAction(ACTIONS.SEARCH_ORDERS, 'searchTerm');
export const searchOrdersSuccess = createAction(ACTIONS.SEARCH_ORDERS_SUCCESS, 'payload');
export const searchOrdersError = createAction(ACTIONS.SEARCH_ORDERS_ERROR, 'errorMessage', 'error');
export const showSearchOrdersModal = createAction(ACTIONS.SHOW_SEARCH_ORDERS_MODAL, 'showSearchOrdersModal');
export const resetSearchOrders = createAction(ACTIONS.RESET_SEARCH_ORDERS);
export const sortSearchOrdersResults = createAction(ACTIONS.SORT_SEARCH_ORDER_RESULTS, 'results', 'columnId', 'direction');
export const sortSearchOrdersResultsSuccess = createAction(ACTIONS.SORT_SEARCH_ORDER_RESULTS_SUCCESS, 'results');

export const createOrderFromOrderLine = createAction(ACTIONS.CREATE_ORDER_FROM_ORDER_LINE, 'salesOrderNumber', 'guidOrderLine');
export const confirmCreateOrder = createAction(ACTIONS.CONFIRM_CREATE_ORDER, 'guidOrderLine');
export const confirmCreateOrderSuccess = createAction(ACTIONS.CONFIRM_CREATE_ORDER_SUCCESS, 'payload');
export const confirmCreateOrderError = createAction(ACTIONS.CONFIRM_CREATE_ORDER_ERROR, 'errorMessage', 'error');
export const cancelCreateOrder = createAction(ACTIONS.CANCEL_CREATE_ORDER);
export const showCreateOrderModal = createAction(ACTIONS.SHOW_CREATE_ORDER_MODAL, 'showCreateOrderModal');
export const redirectToAddOrder = createAction(ACTIONS.REDIRECT_TO_ADD_ORDER);

export const setOrderViewType = createAction(ACTIONS.SET_ORDER_VIEW_TYPE, 'orderViewType');
export const setFilterOptions = createAction(ACTIONS.SET_FILTER_OPTIONS, 'filterOptions');
export const setColumnFilterOptions = createAction(ACTIONS.SET_COLUMN_FILTER_OPTIONS, 'columnFilterOptions');
export const setQuickFilterOptions = createAction(ACTIONS.SET_QUICK_FILTER_OPTIONS, 'quickFilterOptions');

export const loadOrderDetails = createAction(ACTIONS.LOAD_ORDER_DETAILS, 'orderId');
export const loadOrderDetailsSuccess = createAction(ACTIONS.LOAD_ORDER_DETAILS_SUCCESS, 'payload');
export const loadOrderDetailsError = createAction(ACTIONS.LOAD_ORDER_DETAILS_ERROR, 'errorMessage', 'error');
export const showOrderDetailsModal = createAction(ACTIONS.SHOW_ORDER_DETAILS_MODAL, 'showOrderDetailsModal');
export const resetOrderDetails = createAction(ACTIONS.RESET_ORDER_DETAILS);

export const addOrderNote = createAction(ACTIONS.ADD_ORDER_NOTE, 'orderNote');
export const addOrderNoteSuccess = createAction(ACTIONS.ADD_ORDER_NOTE_SUCCESS, 'payload');
export const addOrderNoteError = createAction(ACTIONS.ADD_ORDER_NOTE_ERROR, 'errorMessage', 'error');

export const fetchOrderLineNotes = createAction(ACTIONS.FETCH_ORDER_LINE_NOTES, 'guidOrderLineId');
export const fetchOrderLineNotesSuccess = createAction(ACTIONS.FETCH_ORDER_LINE_NOTES_SUCCESS, 'payload');
export const fetchOrderLineNotesError = createAction(ACTIONS.FETCH_ORDER_LINE_NOTES_ERROR, 'errorMessage', 'error');

export const addOrderLineNote = createAction(ACTIONS.ADD_ORDER_LINE_NOTE, 'orderLine');
export const cancelAddOrderLineNote = createAction(ACTIONS.CANCEL_ADD_ORDER_LINE_NOTE);
export const setNewNote = createAction(ACTIONS.SET_NEW_NOTE, 'newNote');
export const submitOrderLineNote = createAction(ACTIONS.SUBMIT_ORDER_LINE_NOTE);
export const submitOrderLineNoteSuccess = createAction(ACTIONS.SUBMIT_ORDER_LINE_NOTE_SUCCESS, 'payload');
export const submitOrderLineNoteError = createAction(ACTIONS.SUBMIT_ORDER_LINE_NOTE_ERROR, 'errorMessage', 'error');